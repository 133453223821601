import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
//images
import bnr1 from './../../images/line2.png';
import clsimg1 from './../../images/classes/pic1.jpg';
import clsimg2 from './../../images/classes/pic2.jpg';
import clsimg3 from './../../images/classes/pic3.jpg';
import clsimg4 from './../../images/classes/pic4.jpg';
import clsimg5 from './../../images/classes/pic5.jpg';
import clsimg6 from './../../images/classes/pic6.jpg';

const classesBlog = [
	{ images: clsimg1, title: 'PlayGroup', age: '1-2' },	
	{ images: clsimg2, title: 'Nursery', age: '2+' },	
	{ images: clsimg3, title: 'LKG', age: '3+' },	
	{ images: clsimg4, title: 'UKG', age: '4+' },	
	{ images: clsimg1, title: '1st Standard', age: '5+'},	
	{ images: clsimg4, title: '2nd Standard', age: '6+'},	
	{ images: clsimg3, title: '3rd Standard', age: '7+' },	
	{ images: clsimg5, title: '4th Standard', age: '8+'},	
	{ images: clsimg2, title: '5th Standard', age: '9+'},	
	{ images: clsimg6, title: '6th Standard', age: '10+' },	
	{ images: clsimg3, title: '7th Standard', age: '11+' },	
	{ images: clsimg5, title: '8th Standard', age: '12+' },	
];

class Classes extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content">
					<PageTitle  motherMenu="Classes"  activeMenu="Classes" />
					<div className="content-block">
						<div className="section-full bg-white content-inner" style={{backgroundImage:"url("+ bnr1+")", backgroundSize:"contain",backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row sp40">
									{classesBlog.map((data, index)=>(
										<div className="col-lg-4 col-md-6 col-sm-6" key={index}>
											<div className="class-item">
												<div className="class-media"> 
													<img src={data.images} alt=""/>
													<p>
														<span>Class Time: </span> 
														09:30 am - 4:00 pm
													</p>
												</div>
												<div className="class-info">
													<h4><Link to={"#"} onClick={(e)=> {e.preventDefault()}}>{data.title}</Link></h4>
													{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla felis ipsum.</p> */}
													<ul className="schedule">
														<li className="bg-blue class-size"><span>Class Size</span> <span>20 - 30</span> </li>
														<li></li>
														<li className="bg-green years-old"><span>Years Old</span> <span> &nbsp; &nbsp; {data.age}</span> </li>
														{/* <li className="bg-orange tution"><span>Tution Fee</span> <span>$1500</span> </li> */}
													</ul>
												</div>
											</div>
										</div>
									))}	
								</div>
							</div>
						</div>	
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}
}
export default Classes;