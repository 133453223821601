import React,{Component} from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox'; 
import {SRLWrapper, useLightbox} from 'simple-react-lightbox';

import gallery1 from './../../images/gallery/img1.jpg';
import gallery2 from './../../images/gallery/img2.jpg';
import gallery3 from './../../images/gallery/img3.jpg';
import gallery4 from './../../images/gallery/img4.jpg';
import gallery5 from './../../images/gallery/img5.jpg';
import gallery6 from './../../images/gallery/img6.jpg';
import gallery7 from './../../images/gallery/img7.jpg';
import gallery8 from './../../images/gallery/img8.jpg';
import gallery9 from './../../images/gallery/img9.jpg';
import gallery10 from './../../images/gallery/img10.jpg';
import gallery11 from './../../images/gallery/img11.jpg';
import gallery12 from './../../images/gallery/img12.jpg';
import gallery13 from './../../images/gallery/img13.jpg';
import gallery14 from './../../images/gallery/img14.jpg';
import gallery15 from './../../images/gallery/img15.jpg';


//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link to={"#"} onClick={(e) => {
			e.preventDefault()
			openLightbox(props.imageToOpen)
		}} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)
}

const latestBlog = [
	{ image: gallery1, },
	{ image: gallery2, },
	{ image: gallery3, },
	{ image: gallery4, },
	{ image: gallery5, },
	{ image: gallery6, },
	{ image: gallery7, },
	{ image: gallery8, },
	{ image: gallery9, },
	{ image: gallery10, },
	{ image: gallery11, },
	{ image: gallery12, },
	{ image: gallery13, },
	{ image: gallery14, },
	{ image: gallery15, },
	
]
class GallerySlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 3,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<SimpleReactLightbox>
					<SRLWrapper >
						<div className="sprite-nav">
							<Slider className="gallery-carousel owl-carousel owl-theme dots-none " ref={c => (this.slider = c)} {...settings}>
								{latestBlog.map((item, index) => (
									<div className="item p-3" key={index}>
									 <div className="dlab-box frame-box">
											<div className="dlab-thum dlab-img-overlay1 "> 
											<img src={item.image} alt="" />
											<div className="overlay-bx">
												<div className="overlay-icon"> 
														<Iconimage imageToOpen={index} />
												</div>
											</div>
										</div>
									</div>
								</div>
								))}	
							</Slider>	
							<div className="owl-nav" style={{ textAlign: "center" }}>
								<div className=" owl-prev " onClick={this.previous}/>
								<div className="owl-next " onClick={this.next}/>
							</div>
						</div>	
					</SRLWrapper>
				</SimpleReactLightbox>	
			</>	
		)
	}
}
export default GallerySlider;