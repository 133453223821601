import React, {Component} from 'react';
import {Link} from 'react-router-dom';	
import  bnr1 from './../../images/main-slider/slide1.jpg';
import  bnr2 from './../../images/main-slider/slide2.jpg';
import  bnr3 from './../../images/main-slider/slide3.jpg';
import Carousel from 'react-bootstrap/Carousel'

const carouselBlog = [
	{ image: bnr1, para: "We encourage our students to take charge of their education and immerse themselves completely in the learning process. We inspire them to welcome new challenges and kindle their curiosity under the care and guidance of some of the best teachers." },
	{ image: bnr2, para: "We believe our commitment to moral and culture development enables us to continuously enhance our learning process to benefit our children." },
	{ image: bnr3, para: "Our curriculum provides learning experiences to develop your child's imagination and age-appropriate skills." },
];


class BannerSlider extends Component{
			
	render(){
		return(
			<Carousel
				indicators= {false}
				controls={true}
				className="owl-slider owl-carousel owl-theme owl-btn-center-lr dots-none" 
				autoplay={true}
			>
				{carouselBlog.map((item,index)=>(	
					<Carousel.Item key={index}>
						<div className="slide-item">
							<div className="slide-item-img"><img src={item.image}  alt=""/></div>
							<div className="slide-content">
								<div className="slide-content-box container">
									<div className="slide-content-area">
										<h2 className="slider-title" style={{ color:"#006400"}}>Green Apple<span>Academy</span></h2>
										<p className='text-primary'>{item.para}</p>
										<Link to={"/contactus"} className="btn btn-md kids-btn radius-xl">Join us</Link>
									</div>
								</div>
							</div>	
						</div>	
					</Carousel.Item>	
				))}	
			</Carousel>
		)
	}
}

export default BannerSlider;