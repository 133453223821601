import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import bnr1 from './../../images/line2.png';
import team1 from './../../images/team/timg1.jpeg';
import team2 from './../../images/team/timg2.jpeg';
import team3 from './../../images/team/timg3.jpeg';
import team5 from './../../images/team/timg5.jpeg';
import TeacherSlider from '../Element/TeacherSlider';

const teamBlog = [
	{ image: team1 }, { image: team2 }, { image: team3}
	, { image: team5 }, 
];

class Teachers extends Component{
	render(){
		return(
			<Fragment>
				<Header />
					<div className="page-content">
						<PageTitle motherMenu="Teachers" activeMenu="Teachers" />
						<div className="content-block">
							<div className="section-full bg-white content-inner" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
								<div className="container">
									<div className="section-head text-center">
										<h2 className="head-title text-secondry">About the Teachers</h2>
										<p>
											We have an excellent teacher to child ratio at our School to ensure that each child receives the attention he or she needs
										</p>
								</div>
								<TeacherSlider />
									<div className="row">
										{/* {teamBlog.map((data,index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6" key={index}>
												 <div className="dlab-box box-frame1 team-box m-b40">
													<div className=""> 
														<img src={data.image} alt="" />
													</div>
												</div>
											</div>
										))} */}
									</div>	
								</div>
							</div>
						</div>
					</div>
				<Footer />
			</Fragment>
		)
	}
}

export default Teachers;